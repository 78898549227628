<template>
  <div class="row">
    <div
      class="col-12 col-md-3 col-6 p-3 text-center"
      v-for="c in categories"
      :key="c.id">
      <div class="card h-100">
        <div class="card-header">
          <!-- Main Category -->
          <div>
            <a
              class="cat-link header"
              href="javascript: void(0)"
              @click="browseMain(c.main)">
              {{c.main}}
            </a>
          </div>
        </div>
        <div class="card-body">
          <!-- Sub Category -->
          <div
            v-for="s in c.subs"
            :key="s.id"
            class="ms-3">
            <a
              class="cat-link"
              href="javascript: void(0)"
              @click="browseSub(c.main, s.sub)">
              <small>{{s.sub}}</small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['categories'],
    methods:{
      browseMain(main){
        this.$router.push({
          name: 'ProductSearch',
          query: {
            action: 'browseMain',
            category: main
          }
        })
      },
      browseSub(main, sub){
        this.$router.push({
          name: 'ProductSearch',
          query: {
            action: 'browseSub',
            category: main,
            sub
          }
        })
      }
    }
  }
</script>

<style scoped>
  .browser{
    border: 1px solid lightgray;
    border-top: none;
    padding: 5px 20px;
    border-radius: 0px 0px 10px 10px;
  }
  .header{
    font-size:1.1em;
  }
  .cat-selector:hover{
    cursor:pointer;
  }
  .categories{
    max-height: 50vh;
    overflow: hidden;
    overflow-y: scroll;
  }
  .active{
    color: var(--bs-link-hover-color);
    font-weight: 600;
  }
  .cat-link{
    text-decoration: none;
  }
  .cat-link:hover{
    color: var(--bs-link-hover-color);
    font-weight: 600;
    transition: 200ms;
  }
  .cat-box{
    border: 1px solid lightgrey;
    border-radius: 10px;
    height: 100%;
  }
</style>