import axios from 'axios'
import Qs from 'qs'

const initialState = {
  accountDetails: null,
  accountProfile:null,
  accountLoadStatus: false,
  invoices: null,
  activeInvoice: null,
  activeOrder: null,
  activeTab: 'profile',
  activeAging: null,
  pendingOrders: [],
  pendingOrdersLoadStatus: false,
  orderAgainProducts: [],
  authorized: false,
  authError: null,
  orderSearchResults: null,
  orderStatusFilter: null,
  copyInvoice: null
}

export const accountStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setAccountDetails(state, details){
      state.accountDetails = details
    },
    setAccountInvoices(state, invoices){
      state.invoices = invoices
    },
    setAccountLoadStatus(state, status){
      state.accountLoadStatus = status
    },
    setActiveInvoice(state, data){
      state.activeInvoice = {
        details: data.invoice,
        products: data.products
      }
    },
    closeInvoice(state){
      state.activeInvoice = null
    },
    closeOrder(state){
      state.activeOrder = null
    },
    setPendingOrders(state, orders){
      state.pendingOrders = orders
    },
    setPendingOrdersLoadStatus(state, status){
      state.pendingOrdersLoadStatus = status
    },
    viewPendingOrder(state, id){
    },
    setActiveOrder(state, data){
      state.activeOrder = {
        details: data.order,
        products: data.products
      }
    },
    setActiveTab(state, tab){
      state.activeTab = tab
      if(tab == 'invoices'){
        state.activeAging = null
      }
    },
    setOrderAgainProducts(state, products){
      state.orderAgainProducts = products
    },
    setAuthState(state, authState){
      state.authorized = authState
    },
    setAuthError(state, error){
      state.authError = error
    },
    setProfile(state, data){
      state.accountProfile = data
    },
    setActiveAging(state, value){
      if(value){
        state.activeTab = 'invoices'
        state.activeAging = value
      }else{
        state.activeAging = value
      }
    },
    orderProductSearch(state, data){
      state.orderSearchResults = data.results
    },
    clearAccountProductSearch(state){
      state.orderSearchResults = null
    },
    setOrderStatusFilter(state, status){
      state.orderStatusFilter = status
    },
    setCopyInvoice(state, invoice){
      state.copyInvoice = invoice
    }
  },
  actions:{
    accountResetState(context){
      context.commit('resetState')
    },
    getAccountDetails(context){
      context.commit('setAccountLoadStatus', true)
      axios.get('/api/v1/private/account/get_account_details').then(res => {
        context.commit('setActiveTab', 'profile')
        context.commit('setAccountDetails', res.data.account)
        context.commit('setAccountInvoices', res.data.invoices)
        context.commit('setProfile', res.data.customer_data)
        context.commit('setAccountLoadStatus', false)
      })
    },
    viewInvoice(context, id){
      context.commit('setAccountLoadStatus', true)
      let params = {id}
      axios.get('/api/v1/private/account/get_invoice', {params}).then(res =>{
        context.commit('setActiveInvoice', res.data)
        context.commit('setAccountLoadStatus', false)
      })
    },
    viewOrder(context, order){
      context.commit('setAccountLoadStatus', true)
      let params = {order}
      axios.get('/api/v1/private/account/get_order', {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})}).then(res => {
        context.commit('setActiveOrder', res.data)
        context.commit('setAccountLoadStatus', false)
      })
    },
    closeInvoice(context){
      context.commit('closeInvoice')
    },
    closeOrder(context){
      context.commit('closeOrder')
    },
    getPendingOrders(context){
      context.commit('setAccountLoadStatus', true)
      axios.get('/api/v1/private/account/get_open_orders').then(res => {
        context.commit('setPendingOrders', res.data.orders)
        context.commit('setAccountLoadStatus', false)
      })
    },
    setActiveTab(context, tab){
      context.commit('setActiveTab', tab)
    },
    clearOrderAgainProducts(context){
      context.commit('setOrderAgainProducts', [])
    },
    // Account Status Login
    accountAuth(context, password){
      let pass = password.toLowerCase()
      let authPass = this.state.entityStore.entity.password.toLowerCase()
      if(pass == authPass){
        context.commit('setAuthState', true)
      }else{
        context.commit('setAuthError', 'Incorrect Password, Please Try Again')
        setTimeout(()=>{
          context.commit('setAuthError', null)
        }, 3000)
      }
    },
    emailOrder(context, emailData){
      axios.post('/api/v1/private/account/email_order', {email: emailData})
    },
    setActiveAging(context, value){
      context.commit('setActiveAging', value)
    },
    orderProductSearch(context, data){
      context.commit('setAccountLoadStatus', true)
      const params = {
        productIds: data.productIds,
        view: data.view
      }
      axios.get('/api/v1/private/account/search', {params}).then(res=>{
        if(res.data.results){
          context.commit('orderProductSearch', res.data)
        }
        context.commit('setAccountLoadStatus', false)
      })
    },
    clearAccountProductSearch(context){
      context.commit('clearAccountProductSearch')
    },
    setOrderStatusFilter(context, status){
      context.commit('setOrderStatusFilter', status)
    },
    setCopyInvoice(context, invoice){
      context.commit('setCopyInvoice', invoice)
    }
  },
  getters:{
    getAccountDetails: state => {return state.accountDetails},
    getAccountLoadStatus: state => {return state.accountLoadStatus},
    getActiveInvoice: state => {return state.activeInvoice},
    getActiveOrder: state => {return state.activeOrder},
    getPendingOrders: state => {return state.pendingOrders},
    getPendingOrdersLoadStatus: state => {return state.pendingOrdersLoadStatus},
    accountActiveTab: state => {return state.activeTab},
    getAccountAuthorized: state => {return state.authorized},
    getAuthError: state => {return state.authError},
    accountInvoices: state => {return state.invoices},
    openInvoices: state => {return state.accountDetails.items},
    accountProfile: state => {return state.accountProfile},
    activeAging: state => {return state.activeAging},
    orderSearchResults: state => {return state.orderSearchResults},
    orderStatusFilter: state => {return state.orderStatusFilter},
    copyInvoice: state => {return state.copyInvoice}
  }
}