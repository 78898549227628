<template>
  <div class="position-relative">
    <!-- Results & Filter Bar -->
    <div
      v-if="!searching"
      class="sticky-top bg-white p-2 d-none d-sm-block"
      style="border-bottom: 1px solid lightgray;">
      <div class="row">
        <div
          class="col-md-12"
          style="z-index:1; padding: 0px 29px;">
          <!-- Result Label -->
          <div
            v-if="!searching && products"
            class="float-start text-muted d-none d-md-block">
            <!-- Search -->
            <template v-if="query.query">
              <span>Result(s) for:</span>
              <span class="mx-1">'{{ query.query }}'</span>
            </template>
            <!-- Category Browse -->
            <template v-if="query.category">
              <span>Browsing:</span>
              <span class="ms-1">{{ query.category }}</span>
              <span v-if="query.sub">
                >
                {{ query.sub }}
              </span>
            </template>
            <!-- Brand Browse -->
            <template v-if="query.brand">
              <a
                class="btn btn-primary btn-sm me-1"
                href="#"
                @click="backToBrands">
                <i class="fa fa-chevron-left"/>
                Back to Brands
              </a>
              <span>Browsing:</span>
              <span class="ms-1">{{ query.brand }}</span>
            </template>
            <!-- Clearance -->
            <template v-if="query.action == 'clearance'">
              <span>Browsing: Clearance Items</span>
            </template>
            <!-- New Products -->
            <template v-if="['new', 'virtualShowNew'].includes(query.action)">
              <span>Browsing: New Products</span>
            </template>
            <template v-if="query.action == 'virtualShowHot'">
              <span>Browsing: Hot Buys</span>
            </template>
            <!-- Browse Promo -->
            <template v-if="query.action == 'browsePromo'">
              <span>Browsing Promo: '{{ activePromo ? activePromo.promo_title : null}}'</span>
              <!-- {{ activePromo }} -->
            </template>
            <template v-if="['reorderPad', 'virtualShowReorderPad'].includes(query.action)">
              <span>Browsing: Reorder Pad</span>
            </template>
            <template v-if="query.action == 'virtualShowFavorites'">
              <span>Your Favorites</span>
            </template>
            <template v-if="query.action == 'virtualShowDropShips'">
              <span>Drop Shipments</span>
            </template>
            <!-- Product Count -->
            <span class="ms-1">({{ numeral(products.length).format('0,0') }})</span>
            <!-- Promo PDF Download -->
            <a
              v-if="activePromo && activePromo.pdf_url"    
              class="btn btn-primary btn-sm ms-2"
              :href="activePromo.pdf_url"
              target="_blank">
              <i class="fa fa-download"/>
              Download PDF
            </a>
          </div>
          <FilterControls
            v-if="!searching"
            class="float-end"
            :products="products"
            :loading="searching"
            @subSearch="subSearch"/>
        </div>
      </div>
    </div>
    <!-- Results -->
    <div
      v-if="products && !searching && productView == 'grid'"
      class="container-fluid">
      <!-- Gird View -->
      <div class="row animate__animated animate__fadeIn animate__faster">
        <div 
          v-for="(p, i) in products"
          :key="p.product_id"
          class="col-xxl-2 col-xl-3 col-md-4 col-sm-6 d-none d-sm-block">
          <DataLoader
            :index="i"
            :key="i + dataLoaderKey"
            :loadInterval="loadInterval"
            :id="'dataLoader' + '-' + 'card' + '-'+ i"
            @getData="getData"/>
          <ProductCard
            :key="p.product_id"
            :loadImages="loadImages"
            :virtualShow="virtualShow"
            :product="p"/>
        </div>
      </div>
    </div>
    <!-- List View -->
    <div
      v-if="products && !searching && productView == 'list'"
      class="row animate__animated animate__fadeIn animate__faster">
      <div 
        v-for="(p, i) in products"
        :key="p.product_id"
        class="col-12 d-none d-sm-block">
        <DataLoader
          :index="i"
          :key="i + dataLoaderKey"
          :loadInterval="loadInterval"
          :id="'dataLoader' + '-' + 'list' + '-'+ i"
          @getData="getData"/>
        <ProductList
          :key="p.product_id"
          :virtualShow="virtualShow"
          :loadImages="loadImages"
          :product="p"/>
      </div>
    </div>
    <!-- Mobile View -->
    <div
      v-if="!searching"
      class="row animate__animated animate__fadeIn animate__faster">
      <div 
        v-for="(p, i) in products"
        :key="p.product_id"
        class="col-12 d-block d-sm-none">
        <DataLoader
          :index="i"
          :key="i + dataLoaderKey"
          :loadInterval="loadInterval"
          :id="'dataLoader' + '-' + 'mobile' + '-'+ i"
          @getData="getData"/>
        <ProductMobile
          :key="p.product_id"
          :loadImages="loadImages"
          :virtualShow="virtualShow"
          :product="p"/>
      </div>
    </div>
    <div
      v-if="products && !products.length && !searching"
      class="row">
      <div class="col-md-12 text-center">
        <h4 class="text-muted mt-5">No Results</h4>
      </div>
    </div>
    <div
      v-if="searching"
      class="row">
      <div class="col-md-12 text-center mt-5">
        <Spinner/>
      </div>
    </div>
    <MobileFilterButton
      v-if="!searching"
      class="float-end mx-1 d-block d-sm-none"
      :loading="searching"
      :virtualShow="virtualShow"/>
  </div>
</template>
<script>
  import ProductCard from './ProductCard.vue'
  import ProductList from './ProductList.vue'
  import ProductMobile from './ProductMobile.vue'
  import DataLoader from './DataLoader.vue'
  import Spinner from '../../../global/Spinner.vue';
  import MobileFilterButton from '../../menus/mobile/filters/MobileFilterButton.vue'
  import FilterControls from '../../menus/views/FilterControls/FilterControls.vue'
  import {processFilters} from '../../../../repositories/filterActions'

  export default {
    props: ['virtualShow'],
    data(){
      return {
        loadInterval: 24,
        subSearchTerm: null,
        loadImages: []
      }
    },
    created(){
      const route = this.$route
      const params = {
        path: route.path,
        query: route.query,
        meta: {
          scan: route.query.scan,
          barcode: route.query.query,
          barcodeFormat: route.query.barcodeFormat,
          barcodeFormatString: route.query.barcodeFormatString
        }
      }
      this.$store.dispatch('getSearchProducts', params)
    },
    components:{
      ProductCard,
      ProductList,
      ProductMobile,
      DataLoader,
      Spinner,
      MobileFilterButton,
      FilterControls
    },
    methods:{
      // eclipse product data fetch
      getData(index){
        let products
        if(index == 0){
          products = _.slice(this.products, index, this.loadInterval)
        }else{
          products = _.slice(this.products, index + 1, this.loadInterval + (index + 1))
        }
        products = _.map(products, 'product_id')
        if(products?.length){
          this.loadImages = [...this.loadImages, ...products]
          this.$store.dispatch('getProductData', {route: this.route.path, products})
        }
      },
      subSearch(query){
        this.subSearchTerm = query
      },
      backToBrands(){
        if(this.virtualShow){
          this.$router.push({name: 'VirtualShow'})
          this.$store.dispatch('vShowSetView', 'mfg')
        }else{
          this.$router.push({name: 'BrowseBrands'})
        }
      }
    },
    computed:{
      products(){
        const base = processFilters()
        // SubSearch Logic
        if(this.subSearchTerm){
          const results = _.filter(base, function(p){
            return _.every(this.subSearchTerm.split(' '), s => p.description.toLowerCase().includes(s.toLowerCase()))
          }.bind(this))
          return results
        }
        return base
      },
      productView(){return this.$store.getters.productView},
      searching(){return this.$store.getters.productLoadState},
      route(){return this.$router.currentRoute.value},
      query(){return this.route.query},
      reorderPad(){return this.query?.action == 'reorderPad'},
      windowSize(){return window.innerHeight + window.innerWidth},
      activePromo(){return this.$store.getters.productResults.promo_assets || null},
      dataLoaderKey(){
        return ('-' + this.products.length + '-' + this.productView + '-' + this.windowSize).toString()
      }
    }
  }
</script>