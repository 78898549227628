// Availability Methods

// Find matching default sell uom
const uomQtyDetail = (product)=>{
  return _.find(product.eclipse_data.uoms, {name: product.eclipse_data.uom})?.quantity
}

const findMatch = (product, activeUom)=>{
  return _.find(product.eclipse_data.uoms, {name: activeUom.uom})
}

// Build out summarized qty & uom availability objects for either ship branch availability or total branch availability
const buildUom = (product, buildType, branchDetail = null, activeUom = null, env = null, homeBranch = null)=>{
  const uomQty = uomQtyDetail(product)
  let qty
  let match
  if(activeUom) match = findMatch(product, activeUom)
  // Handle different availability types
  switch(buildType){
    case 'shipBranch':
      if(env == "EJW"){
        // Get Home DC branch avail record
        const branchQty = _.find(product.eclipse_data.branches, {id: homeBranch})
        if(match){
          qty = parseInt(branchQty.avail) == 0 ? 0 : ((parseInt(branchQty.avail) * product.eclipse_data.per_qty) / match.quantity)
        }else{
          qty = parseInt(branchQty.avail)
        }
      }else{
        if(match){
          qty = product.eclipse_data.ship_br_available == 0 ? 0 : ((product.eclipse_data.ship_br_available * product.eclipse_data.per_qty) / match.quantity)
        }else{
          qty = product.eclipse_data.ship_br_available
        }
      }
      break
    case 'total':
      if(match){
        qty = product.eclipse_data.total_available == 0 ? 0 : ((product.eclipse_data.total_available * product.eclipse_data.per_qty) / match.quantity)
      }else{
        qty = product.eclipse_data.total_available
      }
      break
    case 'branchDetail':
      if(match){
        qty = branchDetail.avail == 0 ? 0 : (branchDetail.avail / match.quantity) 
      }else{
        qty = branchDetail.avail / product.eclipse_data.per_qty
      }
      break
  }
  // Build return uom object
  const uom = {
    uom: activeUom ? activeUom.uom : product.eclipse_data.uom,
    quantity: buildType == 'branchDetail' ? qty : (qty / uomQty)
  }
  return uom
}

// Export ship branch summarized availability
export const shipBranchAvail = (product, activeUom = null, env=null, homeBranch=null)=>{
  return buildUom(product, 'shipBranch', null, activeUom, env, homeBranch)
}

// Export total branch summarized availability
export const totalAvail = (product, activeUom = null)=>{
  return buildUom(product, 'total', null, activeUom)
}

// Export detailed branch availability
export const branchAvail = (product, activeUom = null)=>{
  const branchData = product.eclipse_data.branches
  const uoms = []
  branchData.forEach((b)=>{
    const uom = buildUom(product, 'branchDetail', b, activeUom)
    uom.branch_id = b.id
    uom.branch_name = b.name
    uoms.push(uom)
  })
  return uoms
}

// Display inbound inventory to frontend user if data is present at branch level
// Display inbound inventory in the active uom if possible
// otherwise standard sell uom inbound inventory will be displayed
export const inboundAvail = (product, branchID, activeUom = null)=>{
  if(!product.inbound_inventory || !_.map(product.inbound_inventory, 'branch_id').includes(branchID)) return
  const inv = _.find(product.inbound_inventory, i => i.branch_id == branchID)
  const match = activeUom ? findMatch(product, activeUom) : null
  let invData = {}
  if(match){
    invData.qty = ((parseInt(inv.next_receipt_qty) * product.eclipse_data.per_qty) / match.quantity)
    invData.uom = activeUom.uom
  }else{
    invData.qty = inv.next_receipt_qty
    invData.uom = product.eclipse_data.uom
  }
  return invData
}