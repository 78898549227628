<template>
  <div 
    class="base"
    :style="product.in_vshow ? buttonStyle : view == 'mobile' ? mobileStyle : gridStyle">
    <!-- Standard Indicators -->
    <a
      v-if="product.in_vshow"
      class="btn btn-sm btn-purple wrapper"
      style="font-size: 12px;padding: 0px 10px;"
      href="#"
      @click="gotoVshow">
      On {{ vShowData ? vShowData.title : 'Virtual Show'}}
      <br>
      Click to View
    </a>
    <span
      v-else-if="promoProduct"
      class="bg-success wrapper">
      On Promo
    </span>
    <span
      v-else-if="newProduct"
      class="bg-info wrapper">
      New
    </span>
    <span
      v-else-if="closeout"
      class="bg-danger wrapper">
      Clearance
    </span>
    <!-- vShow Indicators -->
    <template v-if="virtualShow">
      <span
        v-if="vShowNew"
        class="bg-info wrapper">
        New
      </span>
      <span
        v-else-if="vShowHot"
        class="bg-danger wrapper">
        <i class="fa fa-fire"/>
        HotBuy
      </span>
    </template>
  </div>
</template>

<script>
  import { promoProductV2 } from '../../../../shared/promoHelpers';
  import { showDetail } from '../../../../shared/showDetail';
  
  export default {
    props:['product', 'view', 'virtualShow'],
    data(){
      return{
        gridStyle:{
          position: 'absolute',
          left: '-11px',
          top: '3px'
        },
        mobileStyle:{
          position: 'absolute',
          left: '-11px',
          top: '3px'
        },
        buttonStyle:{
          position: 'absolute',
          left: '-11px',
          top: '0px'
        }
      }
    },
    methods:{
      gotoVshow(){
        this.$router.push({name: 'VirtualShow', query:{action: 'virtualShowProduct', product_id: this.product.product_id}})
      }
    },
    computed:{
      productView(){return this.$store.getters.productView},
      authBranches(){return this.$store.getters.getEntity.activeShipTo.auth_branches},
      promoProduct(){return promoProductV2(this.product)},
      newProduct(){return this.product.new_product_branches?.split(',').some(npb => this.authBranches.includes(npb))},
      closeout(){return this.product.closeout_branches?.split(',').some(cb => this.authBranches.includes(cb))},
      company(){return this.$store.getters.sessionCompany},
      vShowData(){return this.$store.getters.vShowDates},
      showDetail(){
        if(this.virtualShow && this.product.show_details) return showDetail(this.product)
      },
      vShowNew(){
        if(this.virtualShow && this.showDetail){
          switch(this.company){
            case 'Lancaster US':
              return this.showDetail.us_new
            case 'Lancaster Canada':
              return this.showDetail.cad_new
          }
        }
      },
      vShowHot(){
        if(this.virtualShow && this.showDetail){
          switch(this.company){
            case 'Lancaster US':
              return this.showDetail.us_hot_buy
            case 'Lancaster Canada':
              return this.showDetail.cad_hot_buy
          }
        }
      }
    }
  }
</script>

<style scoped>
  .base{
    color: white;
    width:90%;
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    line-height: 15px;
    font-size:12px;
    position: relative;
    z-index: 0;
  }
  .wrapper{
    border-radius: 10px 0px 10px 0px;
    padding: 4px 10px;
  }
</style>