import axios from 'axios'
import Qs from 'qs'
import router from '../../../router'
import {scrollToTop, buildBrands, buildBranches, buildCategories} from '../../../repositories/filterActions'

const initialState = {
  // Search State
  results: null,
  initialResults: null,
  searching: false,
  productView: 'grid',
  filters: {
    options:{
      categories: null,
      brands: null,
      branches: null
    },
    selected: {
      sortBy: 'rel',
      initiator: null,
      categories: null,
      brands: null,
      branches: null
    }
  },
  // Singular Product
  activeProduct: null,
  productLoading: false,
  recentlyViewed: [],
  showFavPanel: false,
  categories: [],
  quickPadPasteLoading: false,
  errorMsg: false,
  similarProducts: [],
  similarLoading: false
}

const processBranches = function(product){
  let base = _.reject(product.eclipse_data.branches, {id: ''})
  return base
}

export const productStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setResults(state, data){
      state.results = data
    },
    searchStatus(state, status){
      state.searching = status
    },
    setCategories(state, categories){
      state.categories = categories
    },
    setCategoryData(state, data){
      state.activeCategory = data.category
      if(data.type == 'sub'){
        state.activeSubCategory = data.name
      }else{
        state.activeSubCategory = null
      }
    },
    setProductLoadState(state, status){
      state.productLoading = status
    },
    setActiveProduct(state, product){
      // Populate recently viewed array
      const match =  _.find(state.recentlyViewed, p => p.product_id == product.product_id)
      if(state.recentlyViewed.length >= 12){
        if(!match){
          state.recentlyViewed.shift()
          state.recentlyViewed.push(product)
        }
      }else{
        if(!match){
          state.recentlyViewed.push(product)
        }
      }
      product.eclipse_data.branches = processBranches(product)
      state.activeProduct = product
    },
    resetActiveProduct(state){
      state.activeProduct = null
    },
    setProductView(state, view){
      state.productView = view
      scrollToTop()
    },
    setShowFav(state, status){
      state.showFavPanel = status
    },
    updateCustomerPartNumber(state, record){
      let match = _.find(state.results.products, p => p.product_id == record.product_id)
      match.customer_part_number = record
    },
    deleteCustomerPartNumber(state, data){
      let match = _.find(state.results.products, p => p.product_id == data.product_id)
      match.customer_part_number = null
    },
    setQuickPadPasteLoading(state, status){
      state.quickPadPasteLoading = status
    },
    setError(state, error){
      state.errorMsg = error
    },
    setSimilarProducts(state, products){
      state.similarProducts = products
    },
    setSimilarLoadState(state, status){
      state.similarLoading = status
    },
    appendProductData(state, data){
      // set base
      const ids = _.map(data.results.products, 'eclipse_id')
      const requestIds = _.map(data.params.products, p => parseInt(p))
      let set = _.filter(state.results.products, p => ids.includes(p.product_id))
    
      // Append Data
      set.forEach(p=>{
        p.eclipse_data = _.find(data.results.products, e => e.eclipse_id == p.product_id)
        p.promo = _.find(data.results.promos, promo => promo.product_id == p.product_id)
        p.show_details = _.filter(data.results.vshow, v => v.product_id == p.product_id)
        p.inbound_inventory = _.filter(data.results.inbound_inventory, i => i.product_id == p.product_id)
        p.customer_part_number = _.find(data.results.part_numbers, pn => pn.product_id == p.product_id)
        p.in_vshow = data.results.in_vshow?.includes(p.product_id)
      })

      // Check for eclipse misses (requested vs provided data) and refetch data for missed products
      const diff = _.xor(requestIds, ids)
      if(diff.length){
        const params = {products: diff}
        this.dispatch('getProductData', params)
      }
      
    },
    sortProducts(state, view){
      state.filters.selected.sortBy = view
    },
    // Set filter Options
    setFilters(state, data){
      state.filters.selected.initiator = null
      state.filters.options.brands = buildBrands(data.products)
      state.filters.options.categories = buildCategories(data.products)
      state.filters.options.branches = buildBranches()
      // state.filters.selected.sortBy = router.currentRoute.value.query?.action == 'reorderPad' ? 'last-ordered' : router.currentRoute.value.query?.query ? 'rel' : 'desc-asc'
      state.filters.selected.sortBy = router.currentRoute.value.query?.query ? 'rel' : 'desc-asc'
      state.filters.selected.categories = null
      state.filters.selected.brands = null
      state.filters.selected.branches = null
    },
    // Set selected options and process products
    filterProducts(state, data){
      // Set Inititiator used for removal of menu options based on parent filter
      if(!state.filters.selected.initiator && data.action != 'branchFilter') state.filters.selected.initiator = data.action
      // Set filter selections
      if(data.action == 'brandFilter') state.filters.selected.brands = data.brands
      if(data.action == 'categoryFilter') state.filters.selected.categories = data.categories
      if(data.action == 'branchFilter') state.filters.selected.branches = data.mode ? {mode: data.mode, branchList: data.branches} : null
      // If no filters applied remove initiator   
      if(!state.filters.selected.brands && !state.filters.selected.categories) state.filters.selected.initiator = null
    }
  },
  actions:{
    productResetState(context){
      context.commit('resetState')
    },
    // Get Categories for menu
    getCategories(context){
      axios.get('/api/v1/private/product/get_categories').then(res =>{
        if(res.status == 200){
          context.commit('setCategories', res.data.categories)    
        }
      })
    },
    getSearchProducts(context, params){
      context.commit('setProductLoadState', true)
      // Close mobile filter menu when searching
      this.dispatch('getFavorites')
      // Perform search
      axios.get('/api/v1/private/product/search', {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})})
      .then(res => {
        if(res.data.results.error){
          router.push({path: '/'})
        }else{
          context.commit('setError', null)
          context.commit('setResults', res.data.results)
          context.commit('setFilters', res.data.results)
          context.commit('setProductLoadState', false)
        }
      })
    },
    getProductData(context, params){
      axios.get('/api/v1/private/product/get_product_data', {params, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})}).then(res=>{
        context.commit('appendProductData', res.data)
      })
    },
    getProduct(context, params){
      context.commit('setProductLoadState', true)
      context.commit('setSimilarLoadState', true)
      axios.get('/api/v1/private/product/get_product', {params})
      .then(res=>{
        context.commit('setError', null)
        context.commit('setActiveProduct', res.data.product)
        context.commit('setProductLoadState', false)
      })
      .catch(error=>{
        context.commit('resetActiveProduct')
        context.commit('setError', error.response.statusText)
        context.commit('setProductLoadState', false)
        router.push({path: '/'})
      })
    },
    getSimilarProducts(context, data){
      axios.get('/api/v1/private/product/get_similar_products', {params: data, paramsSerializer: params => Qs.stringify(params, {arrayFormat: 'brackets'})})
      .then(res=>{
        context.commit('setError', null)
        context.commit('setSimilarProducts', res.data.products)
        context.commit('setSimilarLoadState', false)
      })
      .catch(error=>{
        context.commit('setError', error.response.statusText)
        context.commit('setSimilarProducts', [])
        context.commit('setSimilarLoadState', false)
      })
    },
    // Define the search view
    setProductView(context, view){
      context.commit('setProductView', view)
    },
    setActiveProduct(context, product){
      context.commit('setActiveProduct', product)
    },
    setShowFav(context, status){
      context.commit('setShowFav', status)
    },
    // Update the front end after customer part number backend updated
    updateCustomerPartNumber(context, record){
      context.commit('updateCustomerPartNumber', record)
    },
    // remove from front end once deleted on backend
    deleteCustomerPartNumber(context, data){
      context.commit('deleteCustomerPartNumber', data)
    },
    setQuickPadPasteLoading(context, status){
      context.commit('setQuickPadPasteLoading', status)
    },
    submitQuickPadPaste(context, lines){
      context.commit('setQuickPadPasteLoading', true)
      const params = {
        query:{
          action: 'quickPadPaste',
          lines
        }
      }
      axios.post('/api/v1/private/quick_pad/paste_products', params)
      .then(res => {
        const params = {
          productList: res.data.results,
          vShow: false
        }
        this.dispatch('quickPadAdd', params)
      })
    },
    sortProducts(context, view){
      context.commit('sortProducts', view)
    },
    filterProducts(context, data){
      context.commit('filterProducts', data)
    }
  },
  getters:{
    productResults: state => {return state.results},
    productFilters: state => {return state.filters},
    searchStatus: state => {return state.searching},
    productView: state => { return state.productView},
    getCategories: state => {return state.categories},
    getActiveProduct: state => {return state.activeProduct},
    productLoadState: state => {return state.productLoading},
    recentlyViewed: state =>{return _.sortBy(state.recentlyViewed).reverse()},
    showFavPanel: state => {return state.showFavPanel},
    quickPadPasteLoading: state => {return state.quickPadPasteLoading},
    similarProducts: state => {return state.similarProducts},
    similarLoadState: state => {return state.similarLoading}
  }
}