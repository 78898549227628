<template>
  <div class="row maint-main bg-primary">
    <div class="col-md-12 text-center mt-5">
      <img
        v-if="env == 'LAN'"
        class="img-fluid restrict p-5"
        :src="lanLogo">
      <EjwLogo
        v-if="env == 'EJW'"
        class="mb-5"/>
      <img
        v-if="env == 'EJW-CHENEY'"
        class="img-fluid restrict p-5"
        :src="cheneyLogo">
      <div class="card mx-5">
        <div class="card-body p-5">
          <h1>Temporarily Down for Maintenance</h1>
          <div class="text-muted mt-3">
            <div v-if="env == 'EJW'">
              <div>www.ejwelch.com is currently undergoing maintenance, we apologize for any inconvenience.</div>
              <div>Check back soon! — The EJ Welch Team</div>
            </div>
            <div v-else-if="env == 'EJW-CHENEY'">
              <div>www.cheneryvroomanflooring.com is currently undergoing maintenance, we apologize for any inconvenience.</div>
              <div>Check back soon! — The Cheney Vrooman Team</div>
            </div>
            <div v-else>
              <div>www.lancasterco.com is currently undergoing maintenance, we apologize for any inconvenience.</div>
              <div>Check back soon! — The Lancaster Team</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EjwLogo from './ejw/Logo.vue'
  export default{
    inject: ['env'],
    data(){
      return{
        lanLogo: require('../../../assets/images/lan/LogoColor.png'),
        cheneyLogo: require('../../../assets/images/cheney/CheneyLogoLight.png')
      }
    },
    components:{ EjwLogo }
  }
</script>

<style scoped>
  .maint-main{
    margin: 0px;
    height: 100vh;
  }
  @media(min-width: 610px){
    .restrict{
      max-width: 600px;
    }
  }
</style>