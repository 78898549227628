import axios from 'axios'
import router from '../../../router'
import {toggleShipToSelector} from '../../../shared/ShipToSelector'
import {internalRoles} from '../../../shared/roles'

const initialState = {
  entity: null,
  activeShipTo: null,
  customerData: null,
  priceTypes: null,
  loadStatus: false,
  shipToLoadStatus: false,
  contactBranch: null
}

const shipToChecker = function(entity){
  const isBillTo =  entity.id == entity.bill_to
  let isShipTo
  if(isBillTo){
    isShipTo = _.map(entity.ship_tos, s => s.id).includes(parseInt(entity.id))
  }else{
    isShipTo = !isBillTo
  }
  return isShipTo
}

export const entityStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setEntityLoadStatus(state, status){
      state.loadStatus = status
    },
    setShipToLoadStatus(state, status){
      state.shipToLoadStatus = status
    },
    setEntity(state, data){
      state.entity = data.entity
      state.activeShipTo = data.active_ship_to
      state.customerData = data.customer_data
      state.priceTypes = data.price_types
      state.entity.is_bill_to = state.entity.bill_to == state.entity.id
      state.entity.is_ship_to = shipToChecker(state.entity)
      state.contactBranch = data.contact_branch
    },
    setShipTo(state, data){
      state.activeShipTo = data.ship_to
      state.customerData = data.customer_data
      state.priceTypes = data.price_types
      // Update the active ship to in checkout if users changes shipto during checkout process
      if(this.state.checkoutStore.checkout){
        this.state.checkoutStore.checkout.activeShipTo = data.ship_to.entity_id
      }
    }
  },
  actions:{
    entityResetState(context){
      context.commit('resetState')
    },
    getEntityDetails(context){
      context.commit('setEntityLoadStatus', true)
      axios.get('/api/v1/private/entity/get_details').then(function(res){
        context.commit('setEntity', res.data)
        context.commit('setEntityLoadStatus', false)
        if(!this.state.entityStore.entity.is_ship_to && !internalRoles.includes(this.state.sessionStore.user.role)){
          toggleShipToSelector('show')
        }
      }.bind(this))
    },
    selectShipTo(context, shipTo){
      context.commit('setShipToLoadStatus', true)
      axios.post('/api/v1/private/entity/select_ship_to', {id: shipTo.id}).then(res => {
        context.commit('setShipTo', res.data)
        context.commit('setShipToLoadStatus', false)
        toggleShipToSelector('hide')
        router.push({path: '/'})
        this.dispatch('getCart')
        this.dispatch('vShowClear')
      })
    }
  },
  getters:{
    getEntityLoadStatus: state => {return state.loadStatus},
    getEntity(state){
      return {
        entity: state.entity,
        activeShipTo: state.activeShipTo,
        priceTypes: state.priceTypes,
        customerData: state.customerData
      }
    },
    homeBranch: state => {return state.activeShipTo.entity.dc ? state.activeShipTo.entity.dc : state.activeShipTo.entity.branch_id},
    shipToLoadStatus: state => {return state.shipToLoadStatus},
    promoReceive: state => {return state.customerData ? state.customerData.promo_receive_flag : true},
    isShipTo: state => {
      if(state.entity){
        return state.entity.is_ship_to
      }
    },
    isBillTo: state => {
      if(state.entity){
        return state.entity.is_bill_to
      }
    },
    // when a bill no is not a ship to yet is the activeship to account used to prevent checkout
    billToWarning: state => {return state.entity.is_bill_to && !state.entity.is_ship_to && state.entity.id == state.activeShipTo.entity_id},
    contactBranch: state => {return state.contactBranch}
  }
}