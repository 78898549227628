import env from "../env"
import store from "../stores/store"

// Scroll main content window back to top 
export const scrollToTop = ()=>{
  const content = document.getElementById('content')
  if(content) content.scrollTo(0,0)
}

// Build brand options (product mfg) for front end use
export const buildBrands = (products)=>{
  return _.sortBy(
    _.uniqBy(
      _.map(products, p => {
        return {name: p.mfg, checked: false}
      }), 'name'
    ), 'name'
  )
}

// Build category structure for front end use
export const buildCategories = (products)=>{
  const core = _.uniqBy(_.map(products, p=> { return {main: p.main_category, sub: p.sub_category}}), u => u.main && u.sub)
  const mains = _.uniq(_.map(core, 'main')).sort()
  let categories = []
  mains.forEach(m=>{
    const cat = {
      main: m,
      subs: _.orderBy(_.map(_.map(_.filter(core, {main: m}), 'sub'), s => {return {sub: s, checked: false}}), 'sub')
    }
    if(!_.find(categories, {main: m})) categories.push(cat)
  })
  return categories
}

// Build branch list for front end use
export const buildBranches = ()=>{
  if(env.includes('EJW')){
    const dcs = _.filter(store.getters.getEntity.activeShipTo.auth_branches, ab => ['S27E', 'S60', 'S69'].includes(ab))
    const branches = _.filter(store.getters.getEntity.activeShipTo.auth_branches, ab => !['S27E', 'S60', 'S69'].includes(ab))
    return [...dcs, ...branches]
  }
  return store.getters.getEntity.activeShipTo.auth_branches
}

// Perform product sort
const sortProducts = function(productData, filters){
  let products = productData
  const query = store.getters.productResults.query?.split(' ')

  switch(filters.sortBy){
    case 'rel':
      products = _.orderBy(products, p => _.some(query, q => p.description.toLowerCase().includes(q) ? 1 : 2, 'asc'))
      break
    case 'desc-asc':
      products = _.orderBy(products, 'description', 'asc')
      break
    case 'desc-desc':
      products = _.orderBy(products, 'description', 'desc')
      break
    case 'mfg-asc':
      products = _.orderBy(products, 'mfg', 'asc')
      break
    case 'mfg-desc':
      products = _.orderBy(products, 'mfg', 'desc')
      break
    case 'last-ordered':
      products = _.orderBy(products, p => p.eclipse_data?.last_ordered_date, 'asc')
      break
  }
  return products
}

// Process Filters and return product data set
export const processFilters = ()=>{
  // Set base
  const filters = store.getters.productFilters.selected
  const productData = store.getters.productResults?.products

  // Check for present filters
  if(productData && filters.categories || filters.branches || filters.brands){
    // Set base products
    let products = productData
    
    // Filter categories
    if(filters.categories){
      products = _.filter(products, p => filters.categories.mains.includes(p.main_category))
      if(filters.categories.subs?.length){
        products = _.filter(products, p => filters.categories.subs.includes(p.sub_category))
      }
    }

    // Filter Brands
    if(filters.brands){
      products = _.filter(products, p => filters.brands.includes(p.mfg))
    }

    // Filter Branches
    if(filters.branches?.branchList?.length){
      // Stock flag check present in accesible branches
      if(filters.branches.mode == 'stock'){
        products = _.filter(products, function(p){
          if(p.stock_branches){
            return filters.branches.branchList.some(b=> _.compact(p.stock_branches.split(',')).includes(b))
          }
        })
      }
      // SQL inventory data present in accessible branches
      if(filters.branches.mode == 'avail'){
        products = _.filter(products, function(p){
          if(p.inv_branches){
            return filters.branches.branchList.some(b=> _.compact(p.inv_branches.split(',')).includes(b))
          }
        })
      }
    }
    scrollToTop()
    return sortProducts(products, filters)
  }
  // Return core product data set if no filters
  scrollToTop()
  return store.getters.productResults?.products ? sortProducts(store.getters.productResults.products, filters) : null
}