<template>
  <div class="container-fluid animate__animated animate__fadeIn animate__faster">
    <!-- Header -->
    <div class="row mt-2">
      <div class="col-md-12">
        <img
          :src="header"
          class="img-fluid w-100">
        <div class="text-center my-4">
          <h2 class="poplar">You're Invited</h2>
        </div>
      </div>
      <div class="col-md-4">
        <p>The 2024 Lancaster Buying Show will allow you to meet face-to-face with manufacturers to see new and exciting products hitting the market. Get inspired by discussing ideas and best business practices with other retailers during the seminars, show and events. Lancaster’s Buying Show will be offering tremendous deals to give you a competitive edge to grow your business in the coming year.</p>
      </div>
      <div class="col-md-4">
        <video
          :src="video"
          controls
          controlsList="nodownload"/>
      </div>
      <div class="col-md-4">
        <ul
          class="no-dot"
          style="line-height: 33px;">
          <li>
            <a
              href="https://forms.office.com/pages/responsepage.aspx?id=fPEa6ncEKE2w_7aFUxWX4mIBSbk4s_5IuljxmitwdpJUM0JHQ0lHNzFOT1pEMDBVM0ZGRk80Uk9XNSQlQCN0PWcu"
              target="_blank">
              REGISTER TODAY
            </a>
          </li>
          <li>
            <a
              href="https://www.marriott.com/en-us/hotels/mcosr-renaissance-orlando-at-seaworld/overview/"
              target="_blank">
              HOST HOTEL RESERVATIONS
            </a>
          </li>
          <li>
            <a
              :href="canada ? cadFloorPlan : usFloorPlan"
              target="_blank">
              SHOW FLOOR PLAN
            </a>
          </li>
          <li>
            <a
              :href="canada ? cadShowBook : usShowBook"
              target="_blank">
              SHOW BOOK
            </a>
          </li>
          <li>
            <a
              :href="contestRules"
              target="_blank">
              OFFICIAL CONTEST RULES
            </a>
          </li>
          <li v-if="vShowAllowed">
            <router-link to="/virtual_show">
              ENTER VIRTUAL SHOW
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- Gray section - event times -->
    <div class="row">
      <div class="bg-gray mt-5">
        <div class="row offset">
          <div class="col-md-4 d-flex align-items-center justify-content-center">
            <span
              class="poplar"
              style="color: black;">
              Events & Schedule
            </span>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-12 py-3">
                <h2 style="color: #122c3e;">FEBRUARY 2 - 3 | ORLANDO, FL</h2>
              </div>
              <div class="col-md-6">
                <strong style="color: #f89422;">FRIDAY, FEBRUARY 2ND</strong>
                <ul class="event-times">
                  <li>10:00am - 12:00pm • Seminar</li>
                  <li>12:00pm - 1:00pm • Dealer Packet Pick-Up</li>
                  <li>
                    <strong>1:00pm - 7:00pm • Lancaster Buying Show</strong>
                  </li>
                  <li>1:00pm - 3:00pm • Early Bird Specials</li>
                  <li>5:00pm - 7:00pm • Show Floor Reception</li>
                </ul>
              </div>
              <div class="col-md-6">
                <strong style="color: #f89422;">SATURDAY, FEBRUARY 3RD</strong>
                <ul class="event-times">
                  <li>7:30am - 9:00am • Breakfast</li>
                  <li>
                    <strong>9:00am - 4:00pm • Lancaster Buying Show</strong>
                  </li>
                  <li>11:30pm - 1:30pm • Lunch</li>
                  <li>4:00pm - 4:30pm • Prize Drawings</li>
                  <li>7:00pm - 8:15pm • Dinner at SeaWorld&#174;</li>
                  <li>8:30pm - 9:00pm • SeaWorld&#174; Orca Encounter</li>
                  <li>9:00pm - 10:30pm • SeaWorld&#174; Wild Arctic Exhibit & Ice Breaker Roller Coaster</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Buying Show Deals -->
    <div class="row">
      <div class="col-md-12 text-center my-5">
        <h3 style="font-weight: 500;">BUYING SHOW DEALS</h3>
        <h3 style="font-weight: 500;">EXTENDED PAYMENT TERMS</h3>
        <p
          style="max-width: 40em; margin: 0 auto;"
          class="py-3">
          <span v-if="canada">Not only will you receive the deepest discounts of the year by attending the show. Customers who order more than $750 will also receive net 60 day payment terms.</span>
          <span v-else>Not only will you receive the deepest discounts of the year by attending the show. Show attendees will also receive net 60 day payment terms, giving you the ability to stock up on key items for the spring.</span>
        </p>
        <!-- Dates -->
        <div>
          <strong class="d-block">Preorder Dates: 1/22/2024 - 2/4/2024</strong>
          <strong class="d-block">2024 Lancaster Buying Show Dates: 2/3/2024 - 2/4/2024</strong>
        </div>
      </div>
    </div>
    <!-- Circles -->
    <div class="row">
      <div
        class="container">
        <div class="row mb-5">
          <div class="col text-center">
            <a
              class="btn text-center circle"
              :href="canada ? cadShowBook : usShowBook"
              target="_blank">
              <span>Show Book</span>
              <br>
              <span class="special">Specials</span>
            </a>
          </div>
          <div class="col text-center">
            <a
              class="btn text-center circle"
              :href="usPreShow"
              target="_blank">
              <span>PreShow</span>
              <br>
              <span class="special">Ordering</span>
            </a>
          </div>
          <div class="col text-center">
            <a
              class="btn text-center circle"
              :href="canada ? cadEarlyBirds : usEarlyBirds"
              target="_blank">
              <span>Early Bird</span>
              <br>
              <span class="special">Specials</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Paralax -->
    <div class="row my-5">
      <div
        class="col-md-12 p-0 text-center paralax position-relative"
        :style="{'background-image': `url(${paralax})`}">
        <div class="black-wrapper">
          <h3 class="paralax-text">YOU’RE NOT GOING TO WANT TO MISS OUT...</h3>
        </div>
      </div>
    </div>
    <!-- Image Slider -->
    <div class="row my-5 d-none d-sm-block">
      <div id="carouselId" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner" role="listbox">
          <div
            v-for="(s,i) in slides"
            class="carousel-item"
            :class="{active: i == 0}">
            <div class="row">
              <div
                v-for="img in s"
                :key="img"
                class="col">
                <img
                  :src="img"
                  class="img-fluid">
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselId"
          data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      
      <div class="col-md-12 text-center mt-3 d-none d-lg-block">
        <strong>PICTURES FROM THE 2023 FL BUYING SHOW</strong>
      </div>
    </div>
    <!-- Color Section -->
    <div class="row p-0">
      <!-- Color Section 1 -->
      <div
        class="col-md-12 p-4 text-center text-white"
        style="background: #145091;">
        <span style="font-size: 50px; font-family: POPLARSTD;">CASH SPIFFS</span>
        <div style="max-width: 800px; margin: 0 auto;">
          <p>Cash spiffs are offered by many of our exhibitors as an incentive to attend the show and to focus your buying on particular products, however you must attend the 2024 event to earn them.</p>
        </div>
      </div>
      <!-- Color Section 2 -->
      <div
        class="col-md-12 p-4 text-center text-light"
        style="background-color: #f89422;">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center justify-content-center">
            <span style="font-size: 50px; font-family: POPLARSTD; line-height: 3rem;">REIMBURSEMENT QUALIFICATIONS</span>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="text-center">
                  <img
                    :src="whiteAirplane"
                    class="img-fluid p-2"
                    style="max-width:75px;">
                  <div>
                    <strong>AIRINE REIMBURSEMENT</strong>
                  </div>
                  <div>
                    <small v-if="canada">Purchases of $6,000+ CAD will qualify for up to two coach tickets per store, based on 3% of show purchases, up to a total of $750 CAD.</small>
                    <small v-else>Purchases of $6,000+ USD will qualify for up to two coach tickets per store, based on 3% of show purchases, up to a total of $600 USD.</small>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="text-center">
                  <img
                    :src="whiteHotel"
                    class="img-fluid p-2"
                    style="max-width:75px;">
                    <div>
                      <strong>ROOM REIMBURSEMENT</strong>
                    </div>
                    <div v-if="canada">
                      <small class="d-block">Purchases of $6,000 - $8,999 CAD = One Night</small>
                      <small class="d-block">Purchases of $9,000+ CAD = Two Nights</small>
                    </div>
                    <div v-else>
                      <small class="d-block">Purchases of $6,000 - $8,999 USD = One Night</small>
                      <small class="d-block">Purchases of $9,000+ USD = Two Nights</small>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Prize Banner -->
    <div class="row">
      <div class="col-md-6 text-center my-5">
        <img
          :src="canada ? prizes1Cad : prizes1"
          class="img-fluid">
      </div>
      <div class="col-md-6 text-center my-5">
        <img
          :src="canada ? prizes2Cad : prizes2"
          class="img-fluid">
      </div>
    </div>
    <!-- Seminars   -->
    <div class="row gray-gradient p-3">
      <div class="col-md-12 text-center">
      </div>
      <!-- Seminar-->
      <div class="col-md-12 my-2">
        <div class="bg-light p-4 shadow-sm">
        <div class="row">
          <div class="col-md-12 text-center">
            <span style="font-size: 50px; font-family: POPLARSTD; color: #f89422;">SEMINAR</span>
          </div>
          <div class="col-md-4">
            <strong class="d-block">NHPA’s Merchandising Best Practices and Inventory Management Seminar and Round Table Discussion.</strong>
            <small class="d-block">Friday, February 2 • 10:00am - 12:00pm</small>
          </div>
          <div class="col-md-4">
            <div class="mt-3 text-center">
              <img
                :src="seminarMug"
                class="img-fluid"
                style="max-height: 300px;">
            </div>
          </div>
          <div class="col-md-4">
            <strong class="d-block">Presented by Scott Wright</strong>
            <small class="d-block">Executive Director - Advanced Retail Education Programs</small>
            <p class="mt-4 pt-2">A home improvement industry veteran, Scott has spent the past 20 years developing programs and services to serve NHPA’s mission of helping retailers become better and more profitable merchants. During his tenure, Scott has spearheaded the development of NHPA’s industry-leading training programs. He was also instrumental in developing content for NHPA’s PlanItDIY Consumer Awareness Initiative.</p>
          </div>
        </div>
        </div>
      </div>  
    </div>
    <!-- Join US -->
    <div class="row mt-5">
      <div class="col-md-12 text-center">
        <span
          class="d-block"
          style="font-size:25px;">
          JOIN US SATURDAY EVENING FOR A PRIVAT EDINNER AND
        </span>
        <span
          class="d-block"
          style="font-size: 50px; font-family: POPLARSTD; color: #f89422;">
          LIVE ENTERTAINMENT
        </span>
        <span
          class="d-block"
          style="font-size:25px;">
          at SeaWorld
          &#174;
        </span>
      </div>
    </div>
    <!-- Circle Pictures -->
    <div class="row">
      <div class="col-md-4 text-center">
        <a href="javascript: void(0)">
          <img
            :src="wallrus"
            class="img-fluid circle-img">
        </a>
      </div>
      <div class="col-md-4 text-center">
        <a href="javascript: void(0)">
          <img
            :src="orca"
            class="img-fluid circle-img">
        </a>
      </div>
      <div class="col-md-4 text-center">
        <a
          href="https://www.youtube.com/watch?v=_D7111p-vm0&t=3s"
          target="_blank">
          <img
            :src="rollerCoaster"
            class="img-fluid circle-img circle-link">
        </a>
      </div>
      <div class="col-md-12 text-center">
        <span class="d-block">SeaWorld&#174; Orca Encounter · SeaWorld&#174; Wild Arctic&#174; Exhibit</span>
        <span class="d-block">Caricature Artists · Ice Breaker&#174; Roller Coaster · and more...</span>
      </div>
    </div>
    <!-- Host Hotel -->
    <div
      class="row d-flex align-items-end "
      :style="wavesStyle">
      <div class="col-md-12 text-center">
        <h4 class="text-white">HOST HOTEL</h4>
        <a
          href="https://www.marriott.com/en-us/hotels/mcosr-renaissance-orlando-at-seaworld/overview/"
          target="_blank"
          class="btn btn-outline-light">
          HOTEL RESERVATIONS
        </a> 
      </div>
    </div>
    <div
      class="row pt-3"
      style="background: rgb(29, 152, 140, 0.1)">
      <!-- Hotel Details -->
      <!-- Hotel Images -->
      <div class="col-md-4 text-center">
        <div>
          <strong style="color: #f89422;">LOCATION</strong>
          <div>
            <ul class="no-dot">
              <li>
                Renaissance Orlando at SeaWorld&#174;
                <br>
                <a
                  href="https://www.google.com/maps/place/6677+Sea+Harbor+Dr,+Orlando,+FL+32821,+USA/@28.4130569,-81.4710031,17z/data=!3m1!4b1!4m6!3m5!1s0x88e77e2c5cd2fd95:0x2235426f98b2818c!8m2!3d28.4130569!4d-81.4684282!16s%2Fg%2F11cpll8bfc?entry=ttu"
                  target="_blank">
                  6677 Sea Harbor Drive Orlando, FL 32821
                </a>
              </li>
              <li>
                Phone:
                <a href="tel:800-327-6677">(800) 327-6677</a>
              </li>
            </ul>
          </div>
        </div>
        <img
          :src="hotel1"
          class="img-fluid hotel-img">
      </div>
      <div class="col-md-4 text-center">
        <div>
          <strong style="color: #f89422;">ROOMS</strong>
          <div v-if="canada">Single/Double: $268 CAD rate plus taxes and fee. To qualify for this rate, you must make your room reservations by January 10, 2024 (based on availability).*</div>
          <div v-else>Single/Double: $199 USD rate plus taxes and fee. To qualify for this rate, you must make your room reservations by January 10, 2024 (based on availability).*</div>
        </div>
        <img
          :src="hotel2"
          class="img-fluid hotel-img">
      </div>
      <div class="col-md-4 text-center">
        <div>
          <strong style="color: #f89422;">PARKING</strong>
          <div v-if="canada">Lancaster customers will receive complimentary self-parking for registered overnight guests. Daily self-parking rate for non-registered guests is $49 CAD.</div>
          <div v-else>Lancaster customers will receive complimentary self-parking for registered overnight guests. Daily self-parking rate for non-registered guests is $36 USD.</div>
        </div>
        <img
          :src="hotel3"
          class="img-fluid hotel-img">
      </div>
      <div
        class="col-md-12"
        style="padding: 10px 40px;">
        <span>The Renaissance Orlando at SeaWorld has everything you love about Florida, all in one location. The resort is within walking distance of SeaWorld® and all of Orlando’s most celebrated attractions are just a quick shuttle ride away. This family-friendly resort offers so much to enjoy. Be captivated by the soaring atrium. Embrace the light-filled comforts of guest rooms and suites. Lounge poolside with a tropical drink while the kids play in the water park, with water slides and a toddler play area. For deeper relaxation head to the Wellness Spa for a variety of treatments. Savor every moment with your choice of locallysourced cuisine and artistic cocktails at their restaurants and bars. This resort lets you enjoy the best of Orlando, all in one place.</span>
        <small class="d-block mt-3">
          <strong>*Resort Fee:&nbsp;</strong>
          <span v-if="canada">Rooms rates will be subject to a non-commissionable daily resort fee, REDUCED to $27 CAD (currently $47 CAD per room per night, plus applicable taxes) in effect at the time of check in. This fee will cover several amenities which at this time include:</span>
          <span v-else>Rooms rates will be subject to a non-commissionable daily resort fee, REDUCED to $20 USD (currently $35 USD per room per night, plus applicable taxes) in effect at the time of check in. This fee will cover several amenities which at this time include:</span>
          <ul style="padding-left: 20px;">
            <li>Daily Scheduled Shuttle Service to: Magic Kingdom, Hollywood Studios, Epcot, Animal Kingdom, Disney Springs, Universal Studios</li>
            <li>Daily Enhanced Wireless Internet In Guest Room</li>
            <li>One Time Welcome Resort Beverage Resort Beverage, two coupons per room, per stay (Beer/Wine)</li>
            <li>Daily Basic Wireless Internet In Convention & Meeting Space</li>
          </ul>
        </small>
      </div>
    </div>    
  </div>
</template>

<script>
  import Terms from './Terms.vue'
  export default {
    data(){
      return{
        header: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/FL_TopBanner.png',
        video: 'https://lancaster-video.s3.amazonaws.com/2024_Florida_Buying_Show.mp4',
        paralax: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/paralax.jpg',
        usEarlyBirds: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/FL2024EarlyBirds.pdf',
        whiteAirplane: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Airplane_White.png',
        whiteHotel: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Hotel_White.png',
        seminarMug: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/NHPA_Scott-Wright.jpg',
        orca:'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/SeaWorld_Orca.png',
        rollerCoaster: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/SeaWorld_Roller_Coaster.png',
        wallrus: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/SeaWorld_Wallrus.png',
        wavesStyle: {
          backgroundImage: 'url(https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Waves.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: '300px',
          backgroundPosition: 'bottom',
          paddingBottom: '75px'
        },
        hotel1: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Renaissance_Sign.png',
        hotel2: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Renaissance_Atrim.png',
        hotel3: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/Renaissance_Pool.png',
        prizes1: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/WIN_Prize%20Sponsor1.png',
        prizes2: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/WIN_Prize%20Sponsor2.png',
        prizes1Cad: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/WIN_Prize%20Sponsor_1_CANADA.png',
        prizes2Cad: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/WIN_Prize%20Sponsor_2_CANADA.png',
        usFloorPlan: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024_FL-Buying-Show_Vendor-Listing_Floor-Map_USA.pdf',
        cadFloorPlan: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024_FL-Buying-Show_Vendor-Listing_Floor-Map_CANADA.pdf',
        usShowBook: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-FL-Lancaster-Show-Book_Revisions-Included.pdf',
        usPreShow: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-FL-Lancaster-Show-Book-Front-Info_PreShow_Ordering.pdf',
        usEarlyBirds: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-Lancaster-Show-Book-EB-Specials-FRONT_SECTION.pdf',
        cadShowBook: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024%20FL-Show-Book_CANADA.pdf',
        cadEarlyBirds: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024-Lancaster-Show-Book-EB-Specials-FRONT-SECTION-CA.pdf',
        contestRules: 'https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/2024_FL_Buying_Show_Official_Contest_Rules.pdf'
      }
    },
    components:{
      Terms
    },
    computed:{
      sessionCompany(){return this.$store.getters.sessionCompany},
      slides(){
        let slides = []
        for(let i=1; i<51; i++){
          slides.push(`https://merit-production.s3.amazonaws.com/lanEstore/events/fl_2024/slides/slide${i}.jpg`)
        }
        return _.chunk(slides, 5)
      },
      canada(){return this.sessionCompany == 'Lancaster Canada'},
      vShowAllowed(){return this.$store.getters.vShowAllowed}
    }
  }
</script>

<style scoped>
  video{
    width: 100%;
  }
  .no-dot{
    list-style-type: none;
    padding: 0px;
  }
  ul{
    padding: 0px;
  }
  .poplar{
    font-family: POPLARSTD;
    color: #f89422;
    font-size:50px;
    font-weight: 100;
  }
  .bg-gray{
    background: #e6e7e8;
  }
  .event-times{
    line-height:40px;
    list-style-type: none;
  }
  .circle{
    height: fit-content;
    width: fit-content;
    padding: 30px;
    margin: 0 auto;
    border: 3px solid #f89422;
    border-radius: 50%;
    margin-top: 20px;
  }
  .circle span{
    color: #f89422;
    font-size: 25px;
  }
  .special{
    font-family: POPLARSTD;
    font-size: 50px !important;
  }
  .circle:hover{
    cursor:pointer;
    transform: scale(1.05);
    transition: 150ms ease-in;
    background: #f89422;
    text-shadow: 1px 1px 10px whitesmoke;
  }
  .circle:hover span{
    color: white;
    transition: 0.5s ease-in-out;
  }
  .paralax{
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
  }
  .paralax-text{
    color:white;
  }
  .black-wrapper{
    background: rgb(18, 44, 61, .8);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .slider-img{
    object-fit: cover;
    height: 200px;
    width: 100%;
    min-width: 100px;
  }
  .gray-gradient{
    background: linear-gradient(to bottom, white, #e6e7e8 )
  }
  .circle-img{
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    padding: 30px;
    overflow: hidden;
  }
  @media(max-width: 1500px){
    .circle-img{
      width: 250px;
      height: 250px;
    }
  }
  @media(min-width: 1501px){
    .circle-img{
      width: 400px;
      height: 400px;
    }
  }
  .hotel-img{
    height: 300px;
    width: 400px;
    object-fit: cover;
    object-position: center;
    padding: 10px;
  }
  .circle-link{
    text-decoration: none;
    transition: filter 150ms ease-in;
  }
  .circle-link:hover{
    filter: drop-shadow(2px 4px 6px black) hue-rotate(45deg);
  }
  .carousel-control-next{
    width: 5%;
    opacity: 1;
  }
  .carousel-control-prev{
    width: 5%;
    opacity: 1;
  }
</style>