<template>
  <div class="container-fluid">
    <div
      v-if="env.includes('EJW')"
      class="row">
      <div class="col-md-12 text-center text-muted">
        <h4 class="mt-5">No Events</h4>
        <small>Check Back Later</small>
      </div>
    </div>
    <div v-else>
      <div class="col-md-12 text-center text-muted">
        <h4 class="mt-5">No Events</h4>
        <small>Check Back Later</small>
      </div>
      <!-- <Florida/> -->
    </div>
  </div>
</template>

<script>
  import Florida from './Florida.vue';
  
  export default{
    inject: ['env'],
    components:{
      Florida
    }
  }
</script>