<template>
  <div class="container-fluid mt-2">
    <div class="row">
      <!-- Regions -->
      <div
        v-for="v,k in tmList"
        :key="k"
        class="col-md-12">
        <!-- Region Name -->
        <strong class="text-muted">{{ k }}</strong>
        <div class="row">
          <!-- Sales Reps -->
          <div
            v-for="s in v"
            :key="s.id"
            class="col-xl-3 col-md-4 col-6 p-2">
            <!-- Rep Card -->
            <div
              class="card h-100 card-hover"
              @click="setActiveTm(s)">
              <div class="card-body">
                <div class="row">
                  <!-- <div
                    v-if="s.image_url"  
                    class="col-md-3 d-flex align-items-center p-0">
                    <img
                      v-if="s.image_url"
                      :src="s.image_url"
                      class="img-fluid shadow-sm"
                      style="border-radius: 5px;">
                  </div>   -->
                  <!-- <div
                    v-else
                    class="col-md-3 text-center">
                    <i
                      class="fa fa-user fa-2x text-muted"
                      style="margin: 50% 0;"/>
                  </div> -->
                  <!-- Rep Data -->
                  <div class="col-md-9">
                    {{ s.first_name }}&nbsp;{{ s.last_name }}
                    <div class="text-muted detail">
                      <div>{{ s.title }}</div>
                      <div>
                        Eclipse ID: <strong>{{ s.eclipse_user_id }}</strong>
                      </div>
                      <a :href="'mailto:' + s.email">
                        <small>
                          <i class="fa fa-envelope"></i>
                          {{ s.email }}
                        </small>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../../../global/Spinner.vue'
  
  export default {
    props: ['tmList','user'],
    components:{
      Spinner
    },
    methods: {
      setActiveTm(tm){
        this.$emit('setActiveInManager', tm)
      }
    },
    computed:{
      company(){return this.$store.getters.sessionCompany}
    }
  }
</script>

<style scoped>
  .detail{
    font-size: 12px;
  }
  .card-hover:hover{
    transform: scale(1.02);
    transition: 100ms ease-in-out;
    cursor: pointer;
    box-shadow: 1px 1px 10px lightgray;
  }
</style>

